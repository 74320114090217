import HBEventBusService from "../../../../services/hbBusService";

const voltran = require("/home/node/app/node_modules/voltranjs/src/index");
import React, { useEffect, useState } from "react";
import Tabular from "../../../components/Tabular/Tabular";
import styles from "../../MerchantTabular/MerchantTabular.scss";
import mStyles from "../../MerchantRow/MerchantRowDesktop/MerchantRow.scss";
import ROUTE_PATHS from "Routes/routeConstants";
import { getInitialData } from "../helpers/initial";
import McRow from "../../MerchantRow/MerchantRowDesktop/McRow";
import { RowContextProvider } from "../../../../contexts/RowContext";
import Searchbar from "../../MerchantTabular/common/Searchbar";
import { merchantSuperTab, merchantTabNameEnums, merchantTabs, TABS_TO_QUERY } from "../../MerchantTabular/constants";
import DaVinciService from "../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT, FIRSAT_CADIRI_MERCHANT_ID } from "Utils/constants";
import { removeQueryParam, updateQueryParams } from "Utils/helpers/browser";
import { setWebtrekkConfig } from "../../../../services/webtrekkService";

const MerchantHeaderDesktop = ({ initialState }) => {
  const [filteredProductCount, setFilteredProductCount] = useState();
  const [filteredParameter, setFilteredParameter] = useState();
  const { merchantDetail, showLayoutTab, showSuperTab, merchantId } = initialState.data;
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);

  const [isTab, setIsTab] = useState(initialState.data.activeTab);

  useEffect(() => {
    window?.HbEventBus?.on("storefront.voltran.analytics.utagData.override", data => {
      setFilteredParameter(data?.search_context?.originalSearchTerm);
      setFilteredProductCount(data?.qty_search_result);
    });
    setWebtrekkConfig();
  }, []);

  useEffect(() => {
    HBEventBusService().onTabChangeListen(data => {
      setIsTab(data.tabName);
      const queryParams = new URLSearchParams(window.location.search);
      if (data.tabName === merchantTabNameEnums.MERCHANT_ROW) {
        removeQueryParam("tab", queryParams);
        return;
      }
      updateQueryParams({ tab: TABS_TO_QUERY[data.tabName] }, queryParams);
    });
  }, []);

  const onTabChange = tab => {
    HBEventBusService().onTabChange(tab.name);
    davinci.tabChangeEvent(tab.label);
  };

  const filteredMerchantTabs =
    merchantId === FIRSAT_CADIRI_MERCHANT_ID
      ? merchantTabs.filter(tab => tab.name !== merchantTabNameEnums.ABOUT_SELLER)
      : [...merchantTabs, ...(showSuperTab ? [merchantSuperTab] : [])];
  return (
    <RowContextProvider>
      <div className={[styles.Wrapper, mStyles.wrapper].join(" ")}>
        <McRow initialState={initialState} styles={mStyles} filteredProductCount={filteredProductCount} />
        <div className={styles.TabularWrapper}>
          <div className={styles.Tabular}>
            <Tabular
              tabs={
                showLayoutTab && showLayoutTab === "false"
                  ? filteredMerchantTabs.filter(tab => tab.name !== merchantTabNameEnums.MERCHANT_ROW)
                  : filteredMerchantTabs
              }
              onChange={onTabChange}
              value={isTab}
            />
          </div>
          <div className={styles.Searchbar}>
            <Searchbar initial={filteredParameter} />
          </div>
        </div>
      </div>
    </RowContextProvider>
  );
};

const component = voltran.default.withBaseComponent(MerchantHeaderDesktop, ROUTE_PATHS.MERCHANT_HEADER);

component.services = [voltran.default.SERVICES.merchantContentApi];

component.getInitialState = getInitialData;
component.getSeoState = initialState => {
  const { activeTab } = initialState;
  return { defaultTab: activeTab };
};
export default component;
