const TEST_IDS = {
  development: {
    h1: "header-h1"
  },
  production: {
    h1: "header-h1"
  }
};

module.exports = TEST_IDS[process.env.NODE_ENV];
