/** Dependencies */
import React from "react";
import HModule from "@cosmos/h-module";
import PropTypes from "prop-types";

/** Style */
import styles from "./StickyCoupon.scss";

/** Services */
import { merchantStoreCouponExternalUrl } from "../../../../../services/urls";

/** Hooks */
import useGetElementHeight from "../../../../../utils/hooks/useGetElementHeight";
import { checkSearchParamExistsOnUrl } from "../../../../../utils/helpers/browser";

const StickyCoupon = ({ merchantId }) => {
  const stickyCouponHeight = useGetElementHeight(`#merchantStoreLabel`);
  const modalVisibility = checkSearchParamExistsOnUrl();
  let hideModalOnLoad = "";

  if (modalVisibility) {
    hideModalOnLoad = `&hideModalOnLoad=true`;
  } else {
    hideModalOnLoad = "";
  }

  return (
    <div className={stickyCouponHeight !== "0px" && styles.couponContainer}>
      <div id='merchantStoreLabel'>
        <HModule
          url={`${merchantStoreCouponExternalUrl}/MerchantStoreLabel?merchantId=${merchantId}${hideModalOnLoad}`}
          name='MerchantStoreLabel'
        />
      </div>
    </div>
  );
};

StickyCoupon.propTypes = {
  merchantId: PropTypes.string,
};
export default StickyCoupon;
