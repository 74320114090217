/** Dependencies */
import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import Popover from "./common/Popover";
import PropTypes from "prop-types";
import PopoverWrapper from "../../../Popover/Popover";
/** hooks */
import useOnScreen from "../../../../../utils/hooks/useOnScreen";
/** Style */
import styles from "./MerchantTag.scss";

/** Icons */
import { IconSolidCheck } from "../../../../../assets/components";
import { setCookie, getCookie } from "../../../../../utils/helpers/utils";

const MerchantTag = ({ onClick, calling, eventFunc, onMerchantTagVisible, tagData }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [badgeHover, setBadgeHover] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(108); // min height default
  const tagRef = useRef(null);
  const popoverRef = useRef(null);
  const getCookieBadgePopover = getCookie("mc:seen:badge_popover");
  useOnScreen(tagRef, onMerchantTagVisible);

  useEffect(() => {
    if (popoverRef.current) setOffsetHeight(popoverRef.current.offsetHeight);
  }, [popoverRef.current]);
  useEffect(() => {
    if (badgeHover && calling === "desktop") {
      eventFunc();
      setShowPopover(false);
    }
  }, [badgeHover]);

  useEffect(() => {
    const data = !!getCookieBadgePopover ? JSON.parse(getCookieBadgePopover) : undefined;
    if (!data) {
      setShowPopover(true);
      setCookie("mc:seen:badge_popover", JSON.stringify({ seen: 1 }), 10000);
    }
    if (data) {
      if (data.seen < 2) {
        setShowPopover(true);
        setCookie("mc:seen:badge_popover", JSON.stringify({ seen: data.seen + 1 }), 10000);
      }
    }
  }, []);

  return (
    <div
      className={styles.Badge}
      ref={tagRef}
      onClick={onClick}
      onMouseOver={() => {
        if (calling === "desktop") setBadgeHover(true);
      }}
      onMouseLeave={() => {
        if (calling === "desktop") setBadgeHover(false);
      }}
    >
      <PopoverWrapper type={calling} onClose={() => setShowPopover(false)} show={showPopover}>
        <IconSolidCheck className={styles.BadgeIcon} />
      </PopoverWrapper>
      <div
        className={cx(styles.PopoverWrapper, {
          [styles.PopoverShow]: badgeHover && calling === "desktop",
        })}
        ref={popoverRef}
        style={{ top: -(offsetHeight + 16) }}
      >
        <Popover data={tagData} />
      </div>
    </div>
  );
};

export default MerchantTag;

MerchantTag.propTypes = {
  tagData: PropTypes.string,
  onClick: PropTypes.func,
  calling: PropTypes.string,
  displayedMerchantName: PropTypes.string,
  eventFunc: PropTypes.any,
};
