import {
  checkMerchantIdExists,
  checkMerchantIsActiveOrNot,
  getDisplayMerchantName
} from "../../MerchantRow/helpers/merchant";
import { getMerchantDataWithParamsByIdInternal } from "../../../../services/merchantService";
import { getFollowersCountOfMerchantInternal } from "../../../../services/followerService";
import { getPublishedLayoutByMerchantIdInternal } from "../../../../services/layoutService";
import { throwError } from "Utils/errorHandler/errorHandler";
import { checkSearchParamExistsOnUrlOnServer, getActiveTab } from "Utils/helpers/browser";

export const getInitialData = async (voltranApiManager, context) => {
  // Do not remove "preview"
  /* eslint-disable-next-line no-unused-vars */
  const { preview, merchantId, showLayoutTab, showSuperTab, totalProductCount, h1title, ...rest } = context.query;

  checkMerchantIdExists(merchantId);
  // const searchParamsExist = checkSearchParamExistsExceptAllowed(context.query);

  try {
    // Add this get operation to settleAll after SF Team gives merchant name directly
    const requestArray = [
      // Get merchant data by id
      getMerchantDataWithParamsByIdInternal(merchantId, voltranApiManager, context.cookies, true, true, true),
      // Get total followers of the merchant
      getFollowersCountOfMerchantInternal(merchantId, voltranApiManager, context.cookies),
      // Get layout of the merchant
      getPublishedLayoutByMerchantIdInternal(merchantId, true, voltranApiManager, context.cookies)
    ];

    const response = await Promise.allSettled(requestArray);
    const merchantRowBackground = response[2]?.value?.data?.data?.background;
    const isThereBgColor = merchantRowBackground?.color !== "#FFFFFF" && !!merchantRowBackground?.color;
    const isThereBg = isThereBgColor || !!merchantRowBackground?.imageUrl;
    const isContentDisable = response[0]?.value?.data?.data?.tagList?.some(item =>
      item.includes("content-disable-row-image")
    );

    const backgroundType = () => {
      if (isThereBgColor) {
        return "colorBg";
      }
      if (isContentDisable) {
        return "blankBg";
      }
      return isThereBg ? "imageBg" : "blankBg";
    };

    const data = {
      merchantId,
      merchantDetail: response[0]?.value?.data?.data,
      followerCount: response[1]?.value?.data?.data?.count,
      merchantRowBackground: response[2]?.value?.data?.data?.background,
      totalProductCount,
      backgroundType: backgroundType(),
      hasInitialFilters: checkSearchParamExistsOnUrlOnServer(context.query),
      showLayoutTab,
      showSuperTab: showSuperTab === "true",
      activeTab: getActiveTab(context.query, showLayoutTab, merchantId, showSuperTab === "true")
    };

    if (data) {
      checkMerchantIsActiveOrNot(data);
      data.merchantDetail.displayedName = getDisplayMerchantName(data.merchantDetail, h1title);

      return data;
    }
  } catch (error) {
    throwError(error, context);
  }
};
