import apiManager from "./apiManager";
import { couponExternalUrl, couponInternalUrl } from "./urls";

export const getDailyCouponLimit = (apiClient, cookies) => {
  return apiManager(couponExternalUrl, apiClient, cookies).GET(`/api/v1/customerUsedMerchantCoupons/dailyCouponsLimit`);
};

export const getDailyCouponLimitInternal = (apiClient, cookies, headers) => {
  return apiManager(couponInternalUrl, apiClient, cookies).GET(
    `/api/v1/customerUsedMerchantCoupons/dailyCouponsLimit`,
    headers
  );
};

export const getMerchantsActiveCoupons = (merchantId, apiClient, cookies) => {
  return apiManager(couponExternalUrl, apiClient, cookies).GET(
    `/api/v1/merchantCoupons/merchants/${merchantId}?offset=0&limit=1&couponType=0`
  );
};

export const collectMerchantCoupon = (CouponId, apiClient, cookies) => {
  return apiManager(couponExternalUrl, apiClient, cookies).POST("api/v1/merchantCoupons/merchant/collect", {
    CouponId,
  });
};
