import React from "react";
import styles from "./Popover.scss";
import { IconSolidCheckBlue, IconQuestion } from "../../../../../../assets/components";
import PropTypes from "prop-types";

const types = {
  AuthorizedDealer: {
    title: "Yetkili Satıcı",
    description:
      "resmi satıcı tarafından yetkilendirilmiş, markanın ürünlerini satabilme belgesine sahip satıcılara verilen bir etikettir",
    itemName: "Yetkili satıcı"
  },
  OfficialPartner: {
    title: "Resmi Satıcı",
    description: "marka tescil belgesi sunan ve marka adı ile mağaza adı aynı olan satıcılara verilen bir etikettir.",
    itemName: "Resmi satıcı"
  },
  Both: {
    title: "Resmi Satıcı",
    description: "marka tescil belgesi sunan ve marka adı ile mağaza adı aynı olan satıcılara verilen bir etikettir.",
    itemName: "Resmi satıcı"
  }
};
const Popover = ({ data }) => {
  const hasAuthorizedDealer = data?.partners?.some(partner => partner.partnerShipType === "AuthorizedDealer");
  const hasOfficialPartner = data?.partners?.some(partner => partner.partnerShipType === "OfficialPartner");

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Popover}>
        {!!data?.partners && (
          <ul className={styles.PopoverItems}>
            {data?.partners?.map(partner => (
              <li className={styles.PopoverItem} key={partner.id}>
                <IconSolidCheckBlue className={styles.Icon} />
                <span>{partner.productBrandName}</span>
                <span>{types[partner.partnerShipType].title}</span>
              </li>
            ))}
          </ul>
        )}

        <div className={styles.PopoverBody}>
          {!!data?.partners && (
            <>
              {hasAuthorizedDealer && (
                <p className={styles.InfoText}>
                  <IconQuestion className={styles.Icon} />
                  <strong>{types["AuthorizedDealer"]?.title}</strong>, {types["AuthorizedDealer"].description}
                </p>
              )}
              {hasOfficialPartner && (
                <p className={styles.InfoText}>
                  <IconQuestion className={styles.Icon} />
                  <strong>{types["OfficialPartner"]?.title}</strong>, {types["OfficialPartner"].description}
                </p>
              )}
            </>
          )}
          {!!data?.title && !data?.partners && (
            <p className={styles.InfoText}>
              <IconQuestion className={styles.Icon} />
              <strong>{types[(data?.tagName)]?.title}</strong>, {types[(data?.tagName)]?.description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

Popover.propTypes = {
  data: PropTypes.object.isRequired
};

export default Popover;
