import React, { useState } from "react";
import PropTypes from "prop-types";
import Badge from "@cosmos/badge";
import Popover from "../Popover/PopoverCmp";
import styles from "./BadgeWithPopover.scss";
import useCheckMobileScreen from "../../../utils/hooks/useCheckMobileScreen";

const BadgeWithPopover = ({ text, content, eventFunc, customBadge }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useCheckMobileScreen();

  const handleOpenPopover = () => {
    eventFunc();
    setIsOpen(true);
  };

  return (
    <div className={!!customBadge == false && styles.badge} onMouseLeave={() => setIsOpen(false)}>
      {customBadge ? (
        customBadge({ onMouseEnter: handleOpenPopover })
      ) : (
        <Badge onMouseEnter={handleOpenPopover} color={"#439E4A"} textColor={"#FFFFFF"}>
          {text}
        </Badge>
      )}
      <Popover content={content} isOpen={isMobile ? false : isOpen} />
    </div>
  );
};

BadgeWithPopover.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.element,
};

export default BadgeWithPopover;
