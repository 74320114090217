import React from "react";
import { IconStore } from "../../../../../assets/components";
import styles from "./BlankProfileImage.scss";
import cx from "classnames";

const BlankProfileImage = ({ isMobile = false, isThereBg = false }) => {
  const classNameListContainer = {
    [styles.blankImgContainerMobile]: isMobile && isThereBg,
    [styles.blankImgContainerDesktop]: !isMobile && isThereBg,
    [styles.blankImgContainerDesktop]: !isMobile && !isThereBg,
    [styles.blankImgContainerMobileBlankBg]: isMobile && isThereBg == false,
  };

  return (
    <section className={cx(classNameListContainer)}>
      <IconStore />
    </section>
  );
};

export default BlankProfileImage;
