/* eslint-disable no-console */
// Components
import React, { useState, useRef, useEffect } from "react";
// import PropTypes from "prop-types";
import Button from "@cosmos/button";
import classnames from "classnames";
import { useRowContext } from "../../../contexts/RowContext";
import ModalManager from "./ModalManager/ModalManager";
// Services
import { addFollowerToMerchant, removeFollowerFromMerchant } from "../../../services/followerService";
import { collectMerchantCoupon } from "../../../services/couponService";
import DaVinciService from "../../../services/davinciService";
// Helpers & Constants & Styles
import styles from "./FollowButton.scss";
import appConfig from "../../../appConfig";
import HBEventBusService from "../../../services/hbBusService";

const FOLLOW_AND_WIN_TEXT = "Takip et, kazan";
const FOLLOW_TEXT = "Takip et";
const FOLLOWING_TEXT = "Takip ediliyor";

const initialCouponFailReason = {
  code: null,
  title: "",
  message: "",
};

const FollowButton = ({
  onClicked,
  merchantDetail,
  size = "micro",
  setFollowerCount,
  currentPage,
  device = "desktop",
  className,
}) => {
  const baseModalRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const [couponFailReason, setCouponFailReason] = useState(initialCouponFailReason);
  const {
    isFollowBtnLoading,
    setIsFollowBtnLoading,
    followingStatus,
    setFollowingStatus,
    dailyCouponLimit,
    canBeFollowing,
  } = useRowContext();
  const { id: merchantId } = merchantDetail;
  const davinci = DaVinciService(merchantDetail, currentPage);

  const isSuitableToWinCoupon = () => merchantDetail.coupon && followingStatus?.isFirstTime;
  const followAndWinCondition =
    (dailyCouponLimit === null && merchantDetail.coupon) || (isSuitableToWinCoupon() && dailyCouponLimit > 0);

  // useEffect(() => {
  //   if (isFollowBtnLoading) return;

  //   // Modal Showing Conditions
  //   // If search param exists on url, don't show the modal
  //   if (checkSearchParamExistsOnUrl()) return;

  //   // 1) user logged-in, not following, first time follow, no exists any search param on the url
  //   if (followingStatus) {
  //     const { isFollowing, isFirstTime } = followingStatus;

  //     if (merchantDetail.coupon && !isFollowing && isFirstTime) {
  //       baseModalRef.current.toggleModalVisibility(true, "coupon");
  //       davinci.initialLoadingEvent(merchantDetail.coupon);
  //     }
  //   } else {
  //     // 2) user not-logged-in, active merchant coupon exists
  //     if (merchantDetail.coupon) {
  //       baseModalRef.current.toggleModalVisibility(true, "coupon");
  //       davinci.initialLoadingEvent(merchantDetail.coupon);
  //     }
  //   }
  // }, [isFollowBtnLoading]);
  // Listen IsFollowing event for sync.
  const listenFunc = () => {
    setFollowingStatus((value) => ({ ...value, isFollowing: true, isFirstTime: false }));
  };

  useEffect(() => {
    HBEventBusService().onCouponWinFollow(listenFunc);
  }, []);

  const getBtnTypes = () => ({
    kind: followingStatus?.isFollowing ? "secondary" : "primary",
    text: followingStatus?.isFollowing ? FOLLOWING_TEXT : followAndWinCondition ? FOLLOW_AND_WIN_TEXT : FOLLOW_TEXT,
  });

  const handleChangeFollowStatus = async (isClickedOnRow) => {
    if (disabled) return;
    onClicked(true);
    try {
      setIsFollowBtnLoading(true);
      if (followingStatus?.isFollowing) {
        const response = await removeFollowerFromMerchant(merchantId);
        if (response.status) {
          setFollowingStatus((value) => ({ ...value, isFollowing: false }));
          setFollowerCount((prev) => prev - 1);
          davinci.unfollowEvent(merchantDetail.coupon);
        }
      } else {
        const response = await addFollowerToMerchant(merchantId);
        if (response.status) {
          // When it is needed to show FirstTime modal in some pages
          showSuccessModalOnFollow(isClickedOnRow);
          setFollowingStatus((value) => ({ ...value, isFollowing: true, isFirstTime: false }));
          setFollowerCount((prev) => prev + 1);
          setDisabled(true);
        }
      }
    } catch (error) {
      // console.log("Error:", error);
      window.location.href = `${appConfig.sfLoginUrl}?ReturnUrl=${appConfig.sfBaseUrl}${window?.location?.pathname}`;
    } finally {
      setIsFollowBtnLoading(false);
    }
  };

  const showSuccessModalOnFollow = async (isClickedOnRow) => {
    if (!followingStatus?.isFirstTime) {
      davinci.followAndWinEvent(merchantDetail.coupon, false, true);
      return;
    }

    let isCollected = false;
    const { toggleModalVisibility } = baseModalRef.current;

    if (isSuitableToWinCoupon() && (dailyCouponLimit > 0 || (dailyCouponLimit === 0 && !isClickedOnRow))) {
      try {
        toggleModalVisibility(false, "");
        const { couponId } = merchantDetail.coupon;
        const result = await collectMerchantCoupon(couponId);
        if (result.data.success) {
          toggleModalVisibility(true, "couponSuccess");
          window.Campaign.MERCHANT_STORE_COUPONS.getMerchantCouponList(merchantId);
          isCollected = true;
        }
      } catch (error) {
        //console.log("Error:", error);
        const { code, title, message } = error.response.data;
        setCouponFailReason({ code, title, message });
        toggleModalVisibility(true, "couponFailure");
      } finally {
        davinci.followAndWinEvent(merchantDetail.coupon, isCollected, isClickedOnRow);
      }
    } else {
      toggleModalVisibility(true, "startFollowing");
      davinci.followAndWinEvent(merchantDetail.coupon, false, true);
    }
  };
  const addFollowFunc = () => {
    if (canBeFollowing) {
      handleChangeFollowStatus(false);
    } else {
      window.location.href = `${appConfig.sfLoginUrl}?ReturnUrl=${appConfig.sfBaseUrl}${window?.location?.pathname}`;
    }
  };
  return (
    <>
      <Button
        onClick={() => handleChangeFollowStatus(true)}
        loading={isFollowBtnLoading ? isFollowBtnLoading.toString() : null}
        size={size}
        kind={getBtnTypes()?.kind}
        className={classnames("followBtn", {
          [styles.followAndWinBtn]: !followingStatus?.isFollowing && followAndWinCondition,
          [styles.followButton]: !followingStatus?.isFollowing && !followAndWinCondition,
          [styles.disabled]: disabled,
          [className]: !!className,
          [styles.followedBtn]: followingStatus?.isFollowing,
        })}
        data-testid='followBtn'
      >
        {getBtnTypes()?.text}
      </Button>
      <ModalManager
        ref={baseModalRef}
        merchantDetail={merchantDetail}
        dailyCouponLimit={dailyCouponLimit}
        addFollowFunc={addFollowFunc}
        loading={isFollowBtnLoading}
        device={device}
        davinci={davinci}
        couponFailReason={couponFailReason}
      />
    </>
  );
};

export default React.memo(FollowButton);
