import React from "react";
import cx from "classnames";
import Popover from "@cosmos/popover";
import styles from "./PopoverCmp.scss";

const PopoverCmp = ({ isOpen, content }) => {
  return (
    <div className={cx(styles.popover, { [styles.open]: isOpen })}>
      <Popover description={content} />
    </div>
  );
};

export default PopoverCmp;
