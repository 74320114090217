import React from "react";
import DotSeparator from "./DotSeparator";

import styles from "./WomanEnt.scss";

const WomanEnt = ({ isWomenEnt }) => {
  if (!isWomenEnt) return null;
  return (
    <div className={styles.womanEnt}>
      <DotSeparator />
      <h6 id='mcontent-woman-entrepreneur'>Kadın girişimci mağazası</h6>
    </div>
  );
};

export default WomanEnt;
