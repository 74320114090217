import React, { useState, useEffect, useRef } from "react";
import Input from "@cosmos/input";
import DaVinciService from "../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT } from "../../../../utils/constants";
import { IconSearch } from "../../../../assets/components";

import styles from "./Searchbar.scss";

const Searchbar = ({ initial }) => {
  const [showIcon, setShowIcon] = useState(true);
  const [searchText, setSearchText] = useState("");
  const davinci = DaVinciService({}, DAVINCI_PAGE_MERCHANT);
  const womenEntrepreneur = useRef(null);

  const isWomanEntrepreneur = () => {
    return document.getElementById("mcontent-woman-entrepreneur");
  };

  // Get woman entrepreneur status
  useEffect(() => {
    let counter = 1;
    const womanInterval = setInterval(() => {
      if (isWomanEntrepreneur() !== null) {
        womenEntrepreneur.current = "yes";
        clearInterval(womanInterval);
        return;
      }
      if (counter === 3) {
        womenEntrepreneur.current = "no";
        clearInterval(womanInterval);
      } else {
        counter++;
      }
    }, 750);
  }, []);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    setSearchText(params.get("q") ? params.get("q") : "");
  }, []);

  const handleSearchMerchant = () => {
    if (searchText.trim().length <= 1) {
      return;
    }

    if (davinci) {
      let pathname = null;
      if (window.location.host.includes("hepsiburada.com")) {
        pathname = window.location.pathname.split("/")[2];
      }
      davinci.searchInStoreEvent(searchText, womenEntrepreneur.current, pathname);
    }
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("q", searchText.trim());
    window.location.search = searchParams.toString();
  };

  useEffect(() => {
    if (initial !== "undefined") setSearchText(initial);
  }, [initial]);

  return (
    <div className={styles.Wrapper}>
      <Input
        className={styles.Searchbar}
        type='text'
        placeholder='Satıcı mağazasında ara'
        value={searchText || ""}
        onFocus={() => setShowIcon(false)}
        onBlur={() => setShowIcon(true)}
        onChange={({ target }) => setSearchText(target.value)}
        onKeyPress={({ key }) => key === "Enter" && handleSearchMerchant()}
      />
      {showIcon && (
        <div className={styles.IconWrapper}>
          <IconSearch />
        </div>
      )}
    </div>
  );
};

export default Searchbar;
