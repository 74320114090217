import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Tabular.scss";
const Tabular = ({ tabs, onChange, value }) => {
  return (
    <div className={styles.Tabular}>
      {tabs.map((tab) => (
        <div
          role='button'
          onClick={() => onChange(tab)}
          className={cx(styles.Tab, {
            [styles.Active]: value === tab.name,
            [styles.Passive]: value !== tab.name,
          })}
          key={tab.name}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

Tabular.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }).isRequired
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
export default Tabular;
