import React from "react";
import { numberFormatter } from "../../../../../utils/helpers/number";

import styles from "./MerchantFollower.scss";

function MerchantFollower({ followerCount }) {
  if (!!followerCount === false || followerCount < 49) return null;
  const followerDigit = numberFormatter(followerCount).includes("B") ? 0 : 1;

  return (
    <span className={styles.follower} data-testid='followerCount'>
      <span>{numberFormatter(followerCount, followerDigit)} takipçi</span>
    </span>
  );
}

export default MerchantFollower;
