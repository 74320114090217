import React, { useEffect } from "react";

import styles from "./DotSeparator.scss";

const DotSeparator = () => {
  useEffect(() => {
    const children = [...document.getElementById("merchantSubInfos").children];

    children.forEach((element, index) => {
      if (index === 0) {
        element.firstElementChild.setAttribute("style", "display:none;");
      } else {
        const isThereDot = document.getElementById("dotBr");

        if (!isThereDot && element.offsetTop > children[index - 1].offsetTop) {
          element.firstElementChild.setAttribute("style", "display:none;");

          const br = document.createElement("br");
          const br1 = document.createElement("br");

          br.id = "dotBr";

          element.appendChild(br);
          element.appendChild(br1);
        }
      }
    });
  }, []);

  return (
    <span className={styles.dot} id='dot-separator'>
      &#x2022;
    </span>
  );
};

export default DotSeparator;
