import React from "react";
import PropTypes from "prop-types";
import { TbackgroundType } from "../../helpers/types";
import styles from "./RowBackground.scss";

const RowBackground = ({ backgroundType, merchantRowBackground, isContentDisable }) => {
  const handleStyleBg = () => {
    if (backgroundType === TbackgroundType.colorBg) return merchantRowBackground?.color;
  };

  return backgroundType === TbackgroundType.colorBg ? (
    <div
      className={styles.rowBackground}
      style={{
        background: handleStyleBg(),
      }}
    ></div>
  ) : !isContentDisable && merchantRowBackground?.imageUrl ? (
    <img src={merchantRowBackground?.imageUrl} className={styles.bgImg} />
  ) : null;
};

RowBackground.propTypes = {
  backgroundType: PropTypes.string,
  merchantRowBackground: PropTypes.any,
  isContentDisable: PropTypes.any,
};
export default RowBackground;
