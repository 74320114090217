import React from "react";
import styles from "./Popover.scss";
import cx from "classnames";
import { IconClose } from "../../../assets/components";

const Popover = ({ children, type, onClose, show }) => (
  <div className={styles.PopoverWrapper}>
    <div
      className={cx(styles.PopoverContent, {
        [styles.PopoverContentDesktop]: type === "desktop",
        [styles.PopoverContentMobile]: type === "mobile",
        [styles.Show]: show,
        [styles.Hide]: !show,
      })}
    >
      <div className={styles.PopoverContext}>
        <IconClose className={styles.PopoverClose} onClick={onClose} />
        <p>Mağazanın Resmi/Yetkili satıcısı olduğu markaları buradan görüntüleyebilirsin.</p>
      </div>
    </div>
    <div className={styles.PopoverChildren}>{children}</div>
  </div>
);

export default Popover;
