import React from "react";
import Badge from "@cosmos/badge";

import BadgeWithPopover from "../../../../components/BadgeWithPopover/BadgeWithPopover";

import styles from "./MerchantRating.scss";

const MerchantRating = ({ davinci, merchantDetail, onClick }) => {
  const rating = merchantDetail?.rating?.rating;
  const formattedRating = rating?.toString().replace(".", ",");
  const onHoverRatingPopover = () => {
    davinci.clickHoverRatingEvent();
  };

  if (!!rating === false || rating === 0) return null;

  const customBadge = (props) => (
    <span className={styles.ratingBadge} onClick={onClick} {...props}>
      <span>{formattedRating}</span>
    </span>
  );

  return (
    <BadgeWithPopover
      customBadge={(props) => customBadge(props)}
      text={formattedRating}
      eventFunc={onHoverRatingPopover}
      content={
        <div className={styles.popoverRoot}>
          <div className={styles.evaluation}>
            <span>Satıcı puanı</span>
            <Badge color={"#439E4A"} textColor={"#FFFFFF"} className={styles.popoverBadge} data-testid='rating'>
              <span className={styles.popoverBadgeText}>{formattedRating}</span>
            </Badge>
          </div>
          <div className={styles.popoverBody}>
            {!!merchantDetail?.rating?.feedbackCount && (
              <p className={styles.numOfEvaluations} data-testid='feedback-count'>
                {merchantDetail?.rating?.feedbackCount} değerlendirme
              </p>
            )}
            <p className={styles.popoverBodyText}>
              Satıcı puanı, Hepsiburada.com müşterileri tarafından verilen teslimat süresi, paketleme ve genel alışveriş
              deneyimi puanlarına göre hesaplanmaktadır.
            </p>
          </div>

          <div className={styles.ratingInfo}>
            <a href='https://www.hepsiburada.com/satici-puanlari-nasil-hesaplaniyor' target='_blank' rel='noreferrer'>
              <div className={styles.btn}>
                <span>Puan hesaplama detayları </span>
                <div className={styles.arrowRight}></div>
              </div>
            </a>
          </div>
        </div>
      }
    />
  );
};

export default MerchantRating;
