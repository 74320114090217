import React from "react";
import { numberFormatter } from "../../../../../utils/helpers/number";
import DotSeparator from "./DotSeparator";

import styles from "./MerchantEvaluation.scss";

const MerchantEvaluation = ({ numOfEvaluations }) => {
  if (!!numOfEvaluations === false || numOfEvaluations < 9) return null;
  const evaluationDigit = numberFormatter(numOfEvaluations).includes("B") ? 0 : 1;

  return (
    <span className={styles.numOfEvaluations}>
      <DotSeparator />
      <strong>{numberFormatter(numOfEvaluations, evaluationDigit)} </strong>
      değerlendirme
    </span>
  );
};

export default MerchantEvaluation;
