import React from "react";
import { numberFormatter } from "../../../../../utils/helpers/number";
import DotSeparator from "./DotSeparator";

import styles from "./MerchantProducts.scss";

const MerchantProducts = ({ totalProductCount }) => {
  if (!!totalProductCount === false || totalProductCount < 4) return null;
  const productsDigit = numberFormatter(totalProductCount).includes("B") ? 0 : 1;
  return (
    <span className={styles.prodQuantity}>
      <DotSeparator />
      {/* <strong>{totalProductCount >= 10000 ? "+10.000" : new Intl.NumberFormat().format(totalProductCount)} </strong> */}
      <strong>{numberFormatter(totalProductCount, productsDigit)}</strong>
      ürün
    </span>
  );
};

export default MerchantProducts;
